@import "../../global.scss";
.a {
  height: 100vh;
  display: flex;
  align-items: center;
  // width: 95%;
  margin: 0 auto;
  border-right: 15px solid $primary;

  @include mobile {
    width: 100%;
    height: 100%;
    flex-direction: column;
    text-align: center;
    margin-top: 50px;
    padding: 30px 0;
  }
}

.a-left {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  @include mobile {
    width: 100%;
  }
}

.a-right {
  flex: 4;
  padding-right: 50px;
  align-self: flex-start;

  @include mobile {
    padding: 50px;
  }
  p {
    margin-bottom: 25px;
  }
  span {
    display: inline-block;
    color: $accent;
    padding: 3px 7px;
    border: 1px solid $accent;
    border-radius: 5px;
    margin-right: 20px;
  }
}

.a-card.bg {
  position: absolute;
  top: 50px;
  left: 50px;
  background-color: $primary;
  @include mobile {
    display: none;
  }
}

.a-card {
  width: 60%;
  height: 70vh;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  @include mobile {
    height: 30vh;
  }
}

.a-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.a-title {
  font-size: 60px;
  display: inline-block;
  margin-bottom: 35px;

  // margin-bottom: 1em;
  position: relative;
  z-index: 2;
  &::after {
    content: "";
    height: 10px;
    width: 50%;
    position: absolute;
    bottom: 10px;
    right: 0;
    background-color: $accent;
    transition: all 0.3s ease;
    z-index: -1;
  }
  &:hover::after {
    width: 80%;
    height: 13px;
  }
}

.a-sub {
  margin: 20px 0px;
}

.a-desc {
  font-weight: 300;
}
