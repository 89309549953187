@import "../../global.scss";
.c {
  height: 100%;
  position: relative;
  border-left: 20px solid $accent;
}

.c-bg {
  width: 20px;
  height: 100%;
  background-color: $accent;
  position: absolute;
}

.c-wrapper {
  padding: 50px;
  display: flex;
}

.c-left {
  flex: 1;
}

.c-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.c-title {
  font-size: 60px;
  width: 80%;
  margin-bottom: 25px;
}

.c-info-item {
  display: flex;
  align-items: center;
  margin: 25px 0px;
  font-weight: 300;
  width: 70%;
}

.c-icon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.c-desc {
  font-weight: 200;
}
.c-info-item a:hover {
  color: $accent !important;
}
form {
  margin-top: 20px;
  width: 100%;
}

input {
  display: block;
  width: 50%;
  height: 50px;
  border: none;
  border-bottom: 1px solid black;
  margin: 10px 0px;
  font-size: 14px;
  padding-left: 10px;
}

textarea {
  width: 100%;
  margin: 10px 0px;
  font-size: 14px;
  padding-left: 10px;
}

button {
  border: none;
  padding: 15px;
  background-color: $accent;
  color: white;
  font-weight: 500;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .c {
    height: fit-content;
    position: relative;
    padding: 30px 0 100px;
  }
  .c-wrapper {
    flex-direction: column;
    padding: 0px 50px;
  }

  .c-title {
    font-size: 30px;
  }

  .c-info-item {
    margin: 20px 0px;
    width: 100%;
  }

  .c-desc {
    display: none;
  }

  form {
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
  }

  input {
    width: 100%;
    height: 40px;
    margin: 10px;
    margin-left: 0;
  }

  button {
    margin-top: 10px;
  }
}
