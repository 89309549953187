@import "../../global.scss";

.topbar {
  width: 100%;

  // color: $primary;
  position: fixed;
  top: 0;
  z-index: 9999;
  transition: all 0.3s ease;

  .wrapper {
    width: 100%;
    margin: 0 auto;
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      align-items: center;

      .itemContainer {
        display: flex;
        align-items: center;
        padding: 0 15px;
        align-self: center;
        @include mobile {
          display: none;
        }
        a,
        span {
          text-decoration: none;
          color: $primary;
          font-size: 15px;
          font-weight: 500;

          :hover {
            color: $accent;
            transition: all 0.3s ease;
          }
        }
        .icon {
          font-size: 1.1rem;
          margin-right: 5px;
        }
        .s-icon {
          font-size: 1.8rem;
          @include mobile {
            font-size: 1.3rem;
          }
        }
      }
      .cinfo {
        @include mobile {
          display: none;
        }
      }

      .logo {
        font-size: 1.8rem;
        font-weight: 700;
        text-decoration: none;
        color: $primary;
        margin-right: 40px;
        span {
          color: $accent;
        }
      }
    }

    .right {
      .hamburger {
        width: 32px;
        height: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        span {
          display: block;
          width: 100%;
          height: 3px;
          background-color: $primary;
          transform-origin: left;
          transition: all 0.3s ease;
        }
      }
    }
  }
  &.active {
    background-color: $primary;
    color: $light;
    .logo {
      color: $light !important;
      span {
        color: $light !important;
      }
    }
    a,
    span {
      color: $light !important;
      :hover {
        color: $accent !important;
      }
    }
    .hamburger {
      span {
        &:first-child {
          background-color: $light;
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:last-child {
          background-color: $light;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
